<template>
  <div>
    <legals />
  </div>
</template>
<script>
import Legals from '@/components/system/Auth/Legals'
export default {
  components: {
    Legals,
  },
}
</script>
